<template>
    <div class="flex flex-col flex-1">
        <!-- <SubHeader :backButton="true" backRedirectionUrl="/field-admin?tab=blockadmin" /> -->
        <div v-if="loadingState.fetchingBlockDetails" class="flex items-center justify-center flex-1 bg-white rounded-md gap-2">
            <Loader />
        </div>
        <div v-else class="flex p-4 flex-1 flex-col bg-white rounded-md gap-2">
            <BlockDisplay v-if="blockDetails" :blockDetails="blockDetails" @removeFieldFromBlock="removeFieldFromBlock($event)" @updateBlockDetails="updateBlockDetails($event)" @showAddfieldModal="showAddfieldModal" :loadingState="loadingState" @deleteBlock="handleDeleteBlock($event)" @handleAddFieldToBlock="handleAddFieldToBlock($event)" :allReportsRoles="allReportsRoles" @hanldeFieldReorder="hanldeFieldReorder($event)" @changeCaseCreation="changeCaseCreation($event)" @fetchBlockAdminDetails="fetchBlockAdminDetails" />
        </div>

        <ModalConfirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup"> </ModalConfirm>
    </div>
</template>

<script>
// import SubHeader from "@/components/SubHeader";
import Loader from "@shared/loader";
import BlockDisplay from "../components/blockDisplay.vue";
import axios from "@/axios";
const ModalConfirm = () => import("@/components/modal-confirm");
import { fetchReportVisibility } from "@/modules/field-editor/services.js";

export default {
    name: "block-admin-details",
    components: {
        // SubHeader,
        Loader,
        BlockDisplay,
        ModalConfirm,
    },
    props: {},
    data() {
        return {
            queryId: null,
            tenantId: null,
            loadingState: {
                fetchingBlockDetails: false,
            },
            fieldsList: [],
            blockDetails: null,
            allReportsRoles: null,
        };
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenantId = this.$store.getters.getTenantId;
        this.queryId = this.$route.params.id || null;
        await this.fetchBlockAdminDetails();
        this.allReportsRoles = await fetchReportVisibility();
    },
    computed: {},
    methods: {
        async fetchBlockAdminDetails() {
            this.loadingState.fetchingBlockDetails = true;
            try {
                let url = `/blocks/${this.queryId}`;
                let { data } = await axios.get(url);
                this.blockDetails = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingState.fetchingBlockDetails = false;
        },
        async showAddfieldModal() {
        //     this.loadingState.fetchingFieldsLists = true;
        //     this.$refs["block-add-field"].showFieldModal();
        //     this.fieldsList = await this.fetchFieldsList();
        //     this.loadingState.fetchingFieldsLists = false;
        },
        // async fetchFieldsList() {
        //     let payload = {
        //         tenant_id: this.tenantId,
        //         req_offset: 0,
        //         req_limit: 1,
        //     };
        //     let url = `/fields/${this.tenantId}/all`;
        //     let { data } = await axios.get(url, payload);
        //     return data.fields;
        // },
        async handleAddFieldToBlock(data) {
            let { payload, field } = data;
            try {
                let url = `/blocks/${this.blockDetails.id}/field`;
                let { data } = await axios.post(url, payload);
                this.blockDetails.fields.push({ 
                    ...payload, 
                    id: data.block_field_id, 
                    name: field.name, 
                    label: field.label, 
                    is_cascaded: field.is_cascaded, 
                    history: payload.gap_history 
                });
                this.$toast.success(data.message || "Field Added ");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to add field");
            }
        },
        async removeFieldFromBlock(field) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Field  will be deleted permanently. Please confirm to continue deleting this field.",
            });
            if (promise) {
                try {
                    let url = `/blocks/${this.blockDetails.id}/field/${field.id}`;
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message || "Field deleted");
                    this.blockDetails = { ...this.blockDetails, fields: this.blockDetails.fields.filter((el) => el.id !== field.id) };
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to delete field from block");
                }
            }
            this.$refs["confirm-popup"].close();
        },

        async updateBlockDetails(block) {
            try {
                let payload = {
                    name: block.name,
                    label: block.label,
                    description: block.description,
                    multi: block.multi,
                    min_count: block.min_count,
                    max_count: block.max_count,
                    // valid_gap: block.valid_gap,
                    // gap_history: block.gap_history,
                };
                let url = `/blocks/${block.id}`;
                let { data } = await axios.put(url, payload);
                this.$toast.success(data.message || "Block updated");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update Block");
            }
        },

        async handleDeleteBlock(block) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Block  will be deleted permanently. Please confirm to continue deleting this block.",
            });
            if (promise) {
                try {
                    let url = `/blocks/${block.id}`;
                    let { data } = await axios.delete(url);
                    this.$router.push({
                        name: "Fields",
                        query: {
                            tab: "blockadmin",
                        },
                    });
                    this.$toast.success(data.message || "Block Deleted");
                } catch (error) {
                    this.$toast.error(error.response.data.message || "Failed to delete Block");
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async hanldeFieldReorder(e) {
            let field_list = [];
            this.blockDetails.fields.forEach((el, index) => field_list.push({ block_field_id: el.id, order: index + 1 }));
            let payload = {
                field_list,
            };
            if (e.moved) {
                const url = `/blocks/${this.blockDetails.id}/reorder`;
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || "Failed to reorder Field!");
                }
                // this.$toast.success("Section Reordered");
            }
        },
        async changeCaseCreation(field) {
            try {
                const { data } = await axios.patch(`/blocks/case-form/field/${field.id}`, {
                    state: field.use_in_case_creation,
                });
                console.log('data :>> ', data);
                if (data) this.$toast.success(data.message || 'Field marked for case creation')
            } catch (error) {
                this.$toast.error(error.response.data.detail || `Something went wrong`)
                // this.$toast.success(data.message || 'Field marked for case creation')
                console.log(error, "[+]error while updating blocks case form field");
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
