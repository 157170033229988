<template>
    <div class="flex items-center gap-3 w-full">
        <font-awesome-icon icon="grip-vertical" class="text-gray-500" />
        <div
            class="check-field bg-white transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow"
        >
            <div class="flex gap-3 items-center text-sm">
                <input
                    v-if="edited"
                    :ref="`input_${element.id}`"
                    v-model="element.label"
                    @click.stop="$emit('fieldNameClick', element)"
                    @blur="updateFieldLabel(element)"
                    :class="!element.label ? 'border border-dashed border-red-300 ' : ''"
                    type="text"
                    class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300"
                />
                <span
                    v-else
                    class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md base-content font-semibold"
                >{{ fieldLabel }}</span>
                <div
                    v-if="!['Candidate Consent','Candidate Optional Consent'].includes(element.type)"
                    class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-primary-300 hover:text-white-text transition-all duration-150"
                    :class="edited ? 'bg-primary-300 text-white-text' : 'text-primary'"
                    @click.stop="onEdit(element)"
                >
                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                </div>
                <!-- <span>{{ element.name }}</span> -->
                <GapInfo v-if="element.use_in_gap_validation" :element="element" />
                <CascadedIcon
                    class="w-5"
                    @click.stop="$emit('showFieldSettingsModal', element)"
                />
            </div>
            <div class="control-panel flex items-center gap-3">
                <!-- <button @click.stop="$emit('handleShowPanel', { section, element })" type="button" class="px-4 py-1 font-light text-xs md:text-md text-white-text rounded-full bg-blue-500 border-2 border-white break-words hover:bg-blue-600">Configure Dependent Permissions</button> -->
                <FieldRolePanel
                    :field="element"
                    @PermissionChange="PermissionChange"
                    @change="change"
                    @changeCaseCreation="changeCaseCreation"
                    :allReportsRoles="allReportsRoles"
                    
                />
            </div>
            <div class="flex items-center justify-center">
                <font-awesome-icon class="cursor-pointer text-red-500 hover:text-red-700" @click.stop="$emit('removeFieldFromBlock', element)" icon="trash" />
            </div>
        </div>
    </div>
</template>

<script>
import FieldRolePanel from "@/pages/check-admin/check-dragable-section/components/field-roles-panel";
const CascadedIcon = () => import("@shared/assets/icons/field-cascaded.svg");
const GapInfo = () => import("@shared/components/GapInfo");

export default {
    name: "fields",
    props: {
        element: {
            type: Object,
            default: () => {},
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: '',
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        updateFieldPermissions: {
            type: Function,
        }
    },
    components: {
        CascadedIcon,
        GapInfo,
        FieldRolePanel,
    },
    data() {
        return {
            edited: false,
        }
    },
    computed: {
        fieldLabel() {
            return this.getFieldLabel(this.element);
        },
    },
    methods: {
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        getFieldLabel(field){
            return ['Candidate Consent','Candidate Optional Consent'].includes(field.type) ?
                field.type.replace('Candidate','') :
                field.label;
        },

        async change(event, isRole) {
            try {
                if (isRole) this.updateFieldPermissions(event.role, this.element.id);
                else this.updateFieldAttributesMethod(event, this.element.id);
            } catch (error) {
                if (isRole) {
                    let match = this.element.roles.find((rl) => event.role.role_id === rl.role_id)
                    Object.assign(match, event.backup);
                }
            }
        },
        changeCaseCreation({field}) {
            this.$emit('changeCaseCreation', field);
        },
        onEdit(fieldData) {
            if (!this.element?.label) {
                this.$toast.error("Label is required!");
                return;
            }
            this.edited = !this.edited;
            if (this.edited) {
                this.$emit('fieldNameClick', fieldData)
                this.$nextTick(() => {
                   this.$refs['input_' + fieldData.id].focus();
                });
            }
        },
        updateFieldLabel(field) {
            this.edited = !this.edited;
            this.$emit("updateFieldLabel", field);
        },
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;
    .control-panel {
        margin-left: auto;
    }
}
</style>
